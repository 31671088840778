import React, {useState} from 'react';

const Login=()=>{
    const [username, setUsername]=useState('');
    const [password, setPassword]=useState('');
    const [otpCode, setOtpCode]=useState('');
    const [accessToken, setAccessToken]=useState('');
    const [refreshToken, setRefreshToken]=useState('');
    const [loggedIn, setLoggedIn]=useState(false);
    const [otpRequested, setOtpRequested]=useState(false);

    // TODO: delete the following defaults when finished testing?
    const [title, setTitle]=useState('');
    const [imageAlt, setImageAlt]=useState('');
    const [content, setContent]=useState('');
    const [series, setSeries]=useState('');
    const [imageUrl, setImageUrl]=useState('');
    const [imageFile, setImageFile]=useState(null);
    const [uploadImage, setUploadImage]=useState('');
    const [pageId, setPageId]=useState('');

    const db_url="https://faemckae.net/api/";

    const setFileValues = (e) =>{
        setUploadImage(e.target.value)
        setImageFile(e.target.files[0])
    }
    

    const handleOTPGen=async() => {
        fetch(db_url+'generate-otp/',{
            method: 'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body: JSON.stringify({username, password}),
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            }
            return response.json();
        })
        .then(data=>{
            setOtpRequested(true);
            console.log("Otp Requested");
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const handleLogin=async() => {
        fetch(db_url+'token/',{
            method: 'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body: JSON.stringify({username, password, otp_code: otpCode}),
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            }
            return response.json();
        })
        .then(data=>{
            setAccessToken(data.access);
            setRefreshToken(data.refresh);
            setLoggedIn(true);
            console.log("Logged in");
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const getImage=async() => {

        fetch(db_url+`images/`,{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const postImage=async() => {
        const formData= new FormData();
        formData.append('image',imageFile);

        fetch(db_url+`images/`,{
            method: 'POST',
            headers:{
                // 'Content-Type': 'application/json',
                // 'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
            body: formData,
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const getPost=async() => {
        fetch(db_url+`posts/${pageId}/`,{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            setTitle(data.title);
            setContent(data.content);
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const getBook=async() => {
        fetch(db_url+`books/${pageId}/`,{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            setTitle(data.title);
            if(data.image!==null){
                setImageUrl(data.image);
            } else {
                setImageUrl('');
            }
            setImageAlt(data.image_alt);
            setContent(data.summary);
            setSeries(data.series);
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const putPost=async() => {
        fetch(db_url+`posts/${pageId}/`,{
            method: 'PUT',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
            body: JSON.stringify({title, content}),
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            setTitle(data.title);
            setContent(data.content);
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };
    
    const postPost=async() => {
        fetch(db_url+`posts/`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
            body: JSON.stringify({title, content}),
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            setTitle(data.title);
            setContent(data.content);
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };
 
    const putBook=async() => {
        var jsonStr=JSON.stringify({title, image:imageUrl, image_alt:imageAlt,series, summary:content});
        fetch(db_url+`books/${pageId}/`,{
            method: 'PUT',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
            body: jsonStr,
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            console.log(data);
            setTitle(data.title);
            if(data.image!==null){
                setImageUrl(data.image);
            } //Use this to set the NoImage without re-uploading image
            setImageAlt(data.image_alt);
            setContent(data.summary);
            setSeries(data.series);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    const postBook=async() => {
        var jsonStr=JSON.stringify({title, image:imageUrl,image_alt:imageAlt, series, summary:content});
        fetch(db_url+`books/`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'accept':'*/*',
                'Authorization': `Bearer ${accessToken}`
            },
            mode: 'cors',
            body: jsonStr,
        })
        .then((response)=>{
            console.log(response);
            if(!response.ok){
                throw new Error("Network response not okay: "+response.statusText);
            } else{
                console.log("No errors");
            }
            return response.json();
        })
        .then((data)=>{
            setTitle(data.title);
            setImageUrl(data.image);
            setImageAlt(data.image_alt);
            setContent(data.summary);
            setSeries(data.series);
            console.log(data);
        })
        .catch((error)=>{
            console.error('Error:', error);
        });
    };

    return (
        <div className="page-container">
            {!otpRequested &&(
                <div>
                    <h2>Restricted</h2>
                    <div className="page-container-horizontal-line"></div>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e)=> setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                    <label>Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e)=> setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={handleOTPGen}>Submit</button>
                </div>
            )}
            {!loggedIn && otpRequested &&(
                <div>
                <h2>Restricted</h2>
                <div className="page-container-horizontal-line"></div>
                <div>
                    <p>OTP has been generated, please input OTP code from email</p>
                    <input
                        type="text"
                        value={otpCode}
                        onChange={(e)=> setOtpCode(e.target.value)}
                        required
                    />
                </div>
                <button onClick={handleLogin}>Submit</button>
            </div>
            )}
            {loggedIn && (
                <div>
                    <div>
                        <h2>Admin Dashboard</h2>
                        <div className="page-container-horizontal-line"></div>
                        <input
                            type="text"
                            id="pageID"
                            value={pageId}
                            onChange={(e)=> setPageId(e.target.value)}
                            required
                        />
                        <button onClick={getPost}>Get Post by ID</button>
                        <button onClick={getBook}>Get Book by ID</button>
                    </div>
                    <div>
                        <button onClick={getImage}>Get Images</button>
                        <button onClick={postImage}>Upload Image</button>
                        <label>Upload Image:</label>
                        <input
                            type="file"
                            id="uploadImage"
                            accept="image/*"
                            value={uploadImage}
                            onChange={setFileValues}
                        />
                    </div>
                    <div className="page-container-horizontal-line"></div>
                    <div>
                        <label>Title:</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e)=> setTitle(e.target.value)}
                        />
                        <label>Series:</label>
                        <input
                            type="text"
                            id="series"
                            value={series}
                            onChange={(e)=> setSeries(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>HTTP Image:</label>
                        <input
                            type="text"
                            id="image"
                            value={imageUrl}
                            onChange={(e)=> setImageUrl(e.target.value)}
                        />
                    </div>
                    <div className="page-container-horizontal-line"></div>
                    <div>
                        <p>Image Alt Text:</p>
                        <textarea
                            type="text"
                            id="alt_text"
                            value={imageAlt}
                            onChange={(e)=> setImageAlt(e.target.value)}
                            rows="3"
                        />
                    </div>

                    <div className="page-container-horizontal-line"></div>
                    <div>
                        <p>Content:</p>
                        <textarea
                            type="text"
                            id="content"
                            value={content}
                            onChange={(e)=> setContent(e.target.value)}
                            rows="10"
                        />
                    </div>

                    <div className="page-container-horizontal-line"></div>
                    <div>
                        <button onClick={putBook}>Update Book</button>
                        <button onClick={postBook}>Post Book</button>
                        <button onClick={putPost}>Update Post</button>
                        <button onClick={postPost}>Post Post</button>
                    </div>
                    
                </div>
            )}
        </div>
    );
}
export default Login;