import React, { useRef} from 'react';
import './NavStyle.css';
import PageTemplate from './pages/PageTemplate';

function Navigation() {
  // const topActiveTabRef = useRef(null);
  const pageRef = useRef(null);
//   const [activeTab, setActiveTab] = useState("Home");

  const handleTabChange = (tab) =>{
    console.log(tab);
    // setActiveTab(tab);
    if(pageRef){
        if(pageRef.current){
            pageRef.current.setActiveTabEx(tab);
        }
    }
}

  return (
    <div className="full-width">
      <table className="full-width">
            <tbody>
                <tr>
                  <td className = "dark-color-container">
                    <div className="tab-container">
                      <div className="tab-buttons-no-cursor">
                        <button onClick={() => handleTabChange("Login")}>&nbsp;</button>
                      </div>
                    </div>
                  </td>
                  <td className="dark-color-container">
                    <div className="tab-container">
                        <div className="wide-float-right">
                            <div className="tab-buttons">
                                <button onClick={() => handleTabChange("Home")}>Home</button>
                                <div className="vertical-line"></div>
                                <button  onClick={() => handleTabChange("News")}>News</button>
                                <div className="vertical-line"></div>
                                <button onClick={() => handleTabChange("About")}>About</button>
                            </div>
                            
                        </div>
                    </div>
                  </td>
                </tr>
                <tr className="light-color-container"> 
                    <td className="mid-color-container">
                      <div className="dark-color-container">
                          <div className="side-container">
                              <h3> <u>Series</u> </h3>
                              <div className="side-buttons">
                                  <button  onClick={() => handleTabChange("Series:For Me")}>For Me</button>
                              </div>
                              <div className="horizontal-line"></div>
                             
                          </div>
                      </div>
                    </td>
                    <td >
                        <PageTemplate
                            ref={pageRef}
                        />
                    </td>
                </tr>
            </tbody>
      </table>
    </div>
  );
}

export default Navigation;

// {/* <div className="side-buttons">
// <button  onClick={() => handleTabChange("WIP")}>Works in Progress</button>
// </div> */}