import React from 'react';
import './Header_Footer.css';

const Header= () => {

    return (
        <div className="dark-color-container">
            <div>
                <h1>Fae McKae</h1>
            </div>
            <div className="horizontal-line-header"></div>
        </div>
    );
};

export default Header;