import React from 'react';
import './Header_Footer.css';

const Footer= () => {

    return (
        <div className="dark-color-container-footer">
            <h2>&copy; 2022 Fae McKae LLC</h2>
        </div>
    );
};

export default Footer;