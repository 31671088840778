import React, {useState,forwardRef, useEffect} from 'react';
import './pageStyle.css';
import Login from './Login';

const PageTemplate= forwardRef((props, ref) => {
    const [activeTab, setActiveTab] = useState("Home");
    const [content, setContent] = useState(null);
    const [paginationId, setPaginationId] = useState(1);
    const [previousPage, setPreviousPage]=useState(null);
    const [nextPage, setNextPage]=useState(null);

    const db_url="https://faemckae.net/api/";
    //example
    const setActiveTabEx=(newTab) => {
        setActiveTab(newTab);
    };
    React.useImperativeHandle(ref, () => ({
        setActiveTabEx
    }));

    // Deal with Pagination
    const handleClickPrev=()=>{
        setPaginationId(paginationId-1);
    };
    const handleClickNext=()=>{
        setPaginationId(paginationId+1);
    };

    useEffect(()=>{
        var model_url=db_url;
        // Check for Series:
        const parts=activeTab.split(":");

        if(activeTab==="Home" || activeTab==="About"){
            model_url=model_url+"pages/"
        }
        else if(activeTab==="News"){
            //Get page size=1+
            model_url=model_url+`posts/?page=${paginationId}`
        }
        else{ 
            // Check for Series:
            if(parts.length>1){
                if (parts[0]==="Series"){
                    model_url=model_url+"books/"
                }
            }

        }

        if(model_url!==db_url){
            fetch (model_url, {
                method: 'GET',
                headers:{
                    'Content-Type': 'application/json',
                    'accept':'*/*',
                },
                mode: 'cors'
            })
            .then((response)=>{
                console.log(response);
                if(!response.ok){
                    throw new Error("Network response not okay: "+response.statusText);
                }
                return response.json();
            })
            .then(data=>{
                console.log("data: ",data);
                var results = data.results
                // Parse through for the correct objects
                var contentBuilder="<div class=\"page-container\">";
                if(activeTab==="Home" || activeTab==="About"){
                    var arrItem=results[0]; //Home
                    if(activeTab==="About"){
                        arrItem=results[1];
                    }
                    contentBuilder=contentBuilder+"<h2>"+arrItem.title+"</h2>";
                    contentBuilder=contentBuilder+"<div class=\"page-container-horizontal-line\"></div>";
                    contentBuilder=contentBuilder+arrItem.content+"</div>";
                    setContent(contentBuilder);
                }
                else if(activeTab==="News"){
                    contentBuilder=contentBuilder+"<h2>News</h2>";
                    // const reverseDataOrder = [...results].reverse();
                    results.map(post=>{
                        contentBuilder=contentBuilder+"<div class=\"page-container-horizontal-line\"></div>";
                        contentBuilder=contentBuilder+"<h3>"+post.title+"</h3><br/>"+post.content;
                        return 0;
                    });
                    // console.log(processedPosts);
                    setPreviousPage(data.previous);
                    setNextPage(data.next);
                    contentBuilder=contentBuilder+"<div class=\"page-container-horizontal-line\"></div>";
                    contentBuilder=contentBuilder+"</div>";
                    setContent(contentBuilder);
                }
                else{ 
                    // Check for Series:
                    if(parts.length>1){
                        if (parts[0]==="Series"){
                            var seriesName = parts[1]+" "+parts[0];
                            contentBuilder=contentBuilder+"<h2>"+seriesName+"</h2>";
                            contentBuilder=contentBuilder+"<div class=\"page-container-horizontal-line\"></div><table><tbody>";
                                
                            results.map(book=>{
                                if(book.series===seriesName){
                                    contentBuilder=contentBuilder+"<tr><td class=\"page-container-left\"><img src=\""+book.image+"\" alt=\""+book.image_alt+"\"></img></td>";
                                    contentBuilder=contentBuilder+"<td><h3>"+book.title+"</h3><br/>"+book.summary;
                                    contentBuilder=contentBuilder+"<div class=\"page-container-horizontal-line\"></div></td></tr>";
                                }
                                return 0;
                            });
                            // console.log(processedPosts);
                            contentBuilder=contentBuilder+"</tbody></table></div>";
                            setContent(contentBuilder);
                            
                        }
                    }
                }
            })
            .catch((error)=>{
                console.error('Error:', error);
            });
        }
        
    },[activeTab, paginationId]);

    return (
        <div>
            {activeTab==="Login" &&(
                <div>
                    <Login/>
                </div>
            )}
            {activeTab!=="Login" &&(
                <div dangerouslySetInnerHTML={{__html: content}}/>
            )}
            <div className="page-container">
                <div className="page-container-center">
                {activeTab==="News" && previousPage &&(
                    <button onClick={handleClickPrev}>Prev Page</button>
                )}
                {activeTab==="News" && nextPage &&(
                    <button onClick={handleClickNext}>Next Page</button>
                )}
                </div>
            </div>
        </div>
    );
});

export default PageTemplate;