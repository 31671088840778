import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

function App() {

  
  return (
    <div className="full-space">
      <Header/>
      <Navigation/>
      <Footer/>
    </div>
  );
}

export default App;
